import AppTitle from '@theme/components/atom/AppTitle'
import Container from '@theme/components/utils/Container'
import Divider from '@theme/components/molecule/Divider'
import TopCategory from '@theme/components/category/TopCategory'
import UtilityGridMixin from '~/mixins/UtilityGridMixin'

export default {
  mixins: [UtilityGridMixin],
  components: {
    AppTitle,
    Container,
    Divider,
    TopCategory,
  },
  props: {
    fullViewportWidthWrapper: {
      type: Boolean,
      default: false,
    },
    innerContainerClass: {
      type: String,
      default() {
        return this.$themeSettings.components.TopCategories.innerContainerClass
      },
    },
    showTopCategoriesHeading: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.TopCategories.showTopCategoriesHeading
      },
    },
    topCategoriesHeadingLevel: {
      type: Number,
      default: 2,
    },
    topCategoriesHeadingText: {
      type: String,
      default() {
        return this.$t('TopCategories.heading')
      },
    },
    topCategoryHeadingLevel: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      gridRowClasses: 'row',
      gridColumnClass: 'p-0 mb-5',
      gridColumnClasses: [],
    }
  },
  created() {
    this.gridColumnClasses.push(this.computeColumnClass(this.$themeSettings.components.TopCategories.perLine))
    this.gridColumnClasses.push(this.gridColumnClass)
  },
}
