<script>
export default {
  methods: {
    computeColumnClass(sizes, gridColumns = this.$themeSettings.global.gridColumns) {
      let previousSize = ''
      let result = ''
      if (Object.keys(sizes).length > 0) {
        const allSizes = this.fillMissingBreakpointsToSizes(sizes)
        for (const breakpoint of this.$themeSettings.global.gridBreakpoints) {
          if (allSizes[breakpoint] !== previousSize) {
            const columnName = breakpoint === 'xs' ? '' : `-${breakpoint}`
            const columnWidth =
              allSizes[breakpoint] === 5
                ? 'fifth'
                : allSizes[breakpoint] === 'auto'
                  ? 'auto'
                  : gridColumns / Number(allSizes[breakpoint])
            result += `col${columnName}-${columnWidth} `
            previousSize = allSizes[breakpoint]
          }
        }
      }
      return result.trim()
    },
    convertColumnSizesToString({ sizes = {}, invert = false, gridColumns = this.$themeSettings.global.gridColumns }) {
      let previousSize = ''
      let result = ''
      if (Object.keys(sizes).length > 0) {
        let data = {}
        if (invert) {
          Object.keys(sizes).forEach(breakpoint => {
            data[breakpoint] = this.$themeSettings.global.gridColumns - sizes[breakpoint]
          })
        } else {
          data = sizes
        }
        const allSizes = this.fillMissingBreakpointsToSizes(data)
        for (const breakpoint of this.$themeSettings.global.gridBreakpoints) {
          if (allSizes[breakpoint] !== previousSize) {
            const columnName = breakpoint === 'xs' ? '' : `-${breakpoint}`
            result += `col${columnName}-${allSizes[breakpoint]} `
            previousSize = allSizes[breakpoint]
          }
        }
      }
      return result.trim()
    },
    fillMissingBreakpointsToSizes(originalSizes = {}) {
      const sizes = Object.assign({}, originalSizes)
      let firstValue = 0
      let lastValue = 0
      for (const breakpointCode of this.$themeSettings.global.gridBreakpoints) {
        if (!sizes[breakpointCode]) {
          sizes[breakpointCode] = lastValue
        } else {
          if (!firstValue) {
            firstValue = sizes[breakpointCode]
          }
          lastValue = sizes[breakpointCode]
        }
      }
      for (const breakpointCode of this.$themeSettings.global.gridBreakpoints) {
        if (!sizes[breakpointCode]) {
          sizes[breakpointCode] = firstValue
        }
      }
      return sizes
    },
  },
}
</script>
