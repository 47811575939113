<script>
import SeoMixin from '~/sites/shop/mixins/SeoMixin'

export default {
  extends: SeoMixin,
  methods: {
    getFontsGoogle() {
      return []
    },
    getFontsLocal() {
      return [
        { rel: 'preload', href: '/fonts/roboto-regular-webfont.woff2', as: 'font', crossorigin: 'anonymous' },
        { rel: 'preload', href: '/fonts/roboto-bold-webfont.woff2', as: 'font', crossorigin: 'anonymous' },
        { rel: 'preload', href: '/fonts/roboto-black-webfont.woff2', as: 'font', crossorigin: 'anonymous' },
        { rel: 'preload', href: '/fonts/raleway-regular-webfont.woff2', as: 'font', crossorigin: 'anonymous' },
        { rel: 'preload', href: '/fonts/raleway-medium-webfont.woff2', as: 'font', crossorigin: 'anonymous' },
        { rel: 'preload', href: '/fonts/raleway-semibold-webfont.woff2', as: 'font', crossorigin: 'anonymous' },
        { rel: 'preload', href: '/fonts/raleway-bold-webfont.woff2', as: 'font', crossorigin: 'anonymous' },
      ]
    },
    getSeoMetaAttrsCustom(data) {
      return {
        script: [
          {
            src: 'https://code.jquery.com/jquery-3.6.3.min.js',
          },
          {
            rel: 'preload',
            src: '/assets/js/cookieconsent.js',
          },
          {
            rel: 'preload',
            src: '/assets/js/cc_bepositive_cz.js',
          },
          {
            async: true,
            defer: true,
            src: '/assets/js/cc_bepositive_bottom_bar.js',
          },
        ],
      }
    },
  },
}
</script>
