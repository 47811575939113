<script>
import SeoMixin from '~/sites/cvb/mixins/SeoMixin'

export default {
  extends: SeoMixin,
  methods: {
    getSeoMetaAttrsCustom(data) {
      return {
        script: [
          {
            src: 'https://code.jquery.com/jquery-3.6.3.min.js',
          },
          {
            rel: 'preload',
            src: '/assets/js/cookieconsent.js',
          },
          {
            rel: 'preload',
            src: '/assets/js/cc_bepositive_de.js',
          },
          {
            async: true,
            defer: true,
            src: '/assets/js/cc_bepositive_bottom_bar.js',
          },
        ],
      }
    },
  },
}
</script>
